import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [label, setLabel] = useState('');
  const { i18n } = useTranslation('global');
  const [language, setAppLanguage] = useState('en');
  const [modal, setModal] = useState(
    <img src="/logo512.png" className="w-full h-full object-contain" />
  );
  const [modalHeight, setModalHeight] = useState(600);
  const [modalWidth, setModalWidth] = useState(600);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [headerImage, setHeaderImage] = useState();
  const [checked, setChecked] = useState(false);

  const base_url = String(process.env.REACT_APP_BASE_URL);
  const websocket_url = String(process.env.REACT_APP_WEBSOCKET_URL);
  const hasura_url = String(process.env.REACT_APP_HASURA_URL);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const setLanguage = (language) => {
    setAppLanguage(language);

    i18n.changeLanguage(language);
  };

  return (
    <AppContext.Provider
      value={{
        setLabel,
        label,
        windowWidth,
        setWindowWidth,
        windowHeight,
        modal,
        setModal,
        isModalOpen,
        setIsModalOpen,
        modalHeight,
        setModalHeight,
        modalWidth,
        setModalWidth,
        headerImage,
        setHeaderImage,
        language,
        setLanguage,
        checked,
        setChecked,
        base_url,
        websocket_url,
        hasura_url,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
