import React, { useState } from "react";
import { BsStar, BsStarFill, BsX } from "react-icons/bs";
import { FaCloudDownloadAlt, FaEye } from "react-icons/fa";
import Modal from "react-modal";
import { useSearchContext } from "../application/stores/SearchContext";
import CustomButton from "../components/CustomButton";
import CustomSearchField from "../components/CustomSearchField";
import Header from "../components/Header";
import LoadingButton from "../components/LoadingButton";
import Switch from "../components/Switch";
import CustomDateTimePicker from "../components/widgets/CustomDateTimePicker/CustomDateTimePicker";

const ResultTable = () => {
  const {
    googleResults,
    results,
    query,
    global_search,
    addToFavourite,
    removeDocumentFromFavourite,
  } = useSearchContext();
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const showLocalResult =
    results && results.length > 0 && Array.isArray(results);
  const showGlobalResult =
    global_search && googleResults && googleResults.length > 0;

  if (showLocalResult) {
    return (
      <tbody className="mt-2 just">
        {results.map((e, subIndex) => {
          const favourite = e.favourites.length > 0;
          return (
            <tr
              key={subIndex}
              className={
                (subIndex + 1) % 2 === 0
                  ? "bg-background text-sm"
                  : "bg-white text-sm"
              }
            >
              {/* ID */}
              <td className="px-4 py-2 w-1/2">
                <p>{subIndex + 1}</p>
              </td>
              {/* dharmendra */}
              <td className="px-4 py-2 w-1/2">
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {e.name?.split(e.query).map((part, index) =>
                    index === 0 ? (
                      <span key={index}>{part}</span>
                    ) : (
                      <span key={index}>
                        <strong>{query}</strong>
                        {part}
                      </span>
                    ),
                  )}
                </p>
              </td>
              <td className="px-4 py-2 w-1/2 cursor-pointer">
                <a href={e.file_url} target="_blank" rel="noreferrer">
                  <p>{e.file_url}</p>
                </a>
              </td>
              <td className=" px-4 py-2 mx-4">
                <div className="flex flex-row justify-start items-start">
                  <a
                    href={e.file_url}
                    target="_blank"
                    className=" hover:underline"
                    rel="noreferrer"
                  >
                    <FaCloudDownloadAlt
                      className="mx-1  text-primary"
                      size={22}
                    />
                  </a>

                  {favourite ? (
                    <BsStarFill
                      size={22}
                      className="mx-1  text-font"
                      onClick={() => removeDocumentFromFavourite(e)}
                    />
                  ) : (
                    <BsStar
                      size={22}
                      className="mx-1  text-font"
                      onClick={() => addToFavourite(e)}
                    />
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }
  if (showGlobalResult) {
    return (
      <tbody className="mt-2">
        {googleResults.map((e, subIndex) => {
          const favourite = false;

          return (
            <tr
              key={subIndex}
              className={
                (subIndex + 1) % 2 === 0
                  ? "bg-background text-sm"
                  : "bg-white text-sm"
              }
            >
              {/* ID */}
              <td className="px-4 py-2 w-1/2">
                <p>{subIndex + 1}</p>
              </td>
              <td className="px-4 py-2">
                {/* <a href={e.formattedUrl} target="_blank" rel="noreferrer"> */}
                <p style={{ whiteSpace: "pre-wrap" }}>{e.snippet}</p>
                {/* </a> */}
              </td>
              <td className="px-4 py-2">
                <a href={e.formattedUrl} target="_blank" rel="noreferrer">
                  {e.title}
                </a>
              </td>
              <td className=" px-4 py-2 mx-4 w-16">
                <div className="flex flex-row">
                  <FaEye
                    className="mx-1  text-primary cursor-pointer"
                    onClick={openModal}
                    size={22}
                  />
                  {favourite ? (
                    <BsStarFill
                      size={22}
                      className="   text-font"
                      onClick={() => removeDocumentFromFavourite(e)}
                    />
                  ) : (
                    <BsStar
                      size={22}
                      className="mx-1  text-font"
                      onClick={() => addToFavourite(e)}
                    />
                  )}
                </div>
                {/* Modal */}
                {isModalOpen && (
                  <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Modal"
                    style={{
                      overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      },
                      content: {
                        width: "50%",
                        height: "50%",
                        top: "25%",
                        left: "25%",
                        borderRadius: "8px",
                        padding: "20px",
                      },
                    }}
                  >
                    <div className="">
                      <div className="">
                        <div className="flex flex-row justify-between items-center">
                          <h2 className="font-bold text-font">
                            Query - {query}
                          </h2>
                          {
                            <BsX
                              size={32}
                              className="mx-1 text-font cursor-pointer"
                              onClick={closeModal}
                            />
                          }
                        </div>
                        <a
                          href={e?.formattedUrl}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <p className="hover:underline">{e?.snippet}</p>
                        </a>
                      </div>
                    </div>
                  </Modal>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }
};

const Search = () => {
  const {
    intl_search,
    loadingText,
    stopExecution,
    table_header,
    aiResultTableHeader,
    setquery,
    llmRes,
    fromDate,
    toDate,
    handleFromDateChange,
    handleToDateChange,
    global_search,
    handleGlobalSearch,
    setactiveIndex,
    handleSearch,
    loading,
    handleIntlSearch,
  } = useSearchContext();

  const extractTextFromHTML = (htmlString) => {
    // Create a temporary element
    const tempElement = document.createElement("div");

    // Set the HTML content to the temporary element
    tempElement.innerHTML = htmlString;

    // Get the text content from the temporary element
    const textContent = tempElement.textContent || tempElement.innerText || "";

    // Clean up: Remove the temporary element if needed
    // tempElement.remove(); // Uncomment if you want to remove the temporary element

    return textContent;
  };

  return (
    <div className="flex flex-col justify-start item bg-background">
      {/* Search Section */}
      <Header label={"Search"} />
      <div className="bg-background p-4">
        <div className="flex flex-col justify-start items-center h-18  bg-white">
          <div className="flex flex-row justify-between items-center">
            <div className="mx-8">
              <Switch
                isActive={intl_search}
                label={"Intelligent Search"}
                onChange={handleIntlSearch}
              />
            </div>
            <div className="mx-8">
              <Switch
                isActive={global_search}
                label={"Global Search"}
                onChange={handleGlobalSearch}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            {
              <div className="flex flex-row  justify-between items-start mx-2">
                <CustomDateTimePicker
                  label="Start Date"
                  value={fromDate}
                  onChange={(ev) => handleFromDateChange(ev)}
                />
                <CustomDateTimePicker
                  label="End Date"
                  value={toDate}
                  onChange={(ev) => handleToDateChange(ev)}
                />
              </div>
            }
          </div>
          <div className="flex flex-row justify-start items-start mx-1  w-full  rounded-lg pl-8 pt-16 pb-8 pr-16 ">
            <CustomSearchField
              hintText={"Search in results.."}
              onChange={(ev) => setquery(ev.target.value)}
            />
            <div className="flex flex-row ml-2">
              {loading && <LoadingButton />}
              {!loading && (
                <CustomButton label={"Search"} onClick={handleSearch} />
              )}
              <CustomButton
                style={
                  "bg-white border border-primary w-full h-55 text-primary font-bold py-2 px-4 ml-2 rounded hover:bg-background-600 focus:ring focus:ring-background"
                }
                label={"Stop"}
                onClick={stopExecution}
              />
            </div>
          </div>
        </div>
      </div>

      {/* AI Result Section */}
      <div className="bg-background p-4">
        <div className="bg-white w-full p-2 rounded-sm">
          <div className="flex flex-col justify-start items-start">
            <div className="flex flex-row w-full justify-between items-stretch bg-silver bg-opacity-50">
              {aiResultTableHeader.map((e, index) => (
                <th
                  key={index}
                  className={`text-left font-times font-bold text-xl p-2 `}
                >
                  <div className={`text-black`}>{e.name}</div>
                </th>
              ))}
            </div>
            {loading && loadingText}
            <p className="bg-white p-4 rounded-md font-times text-md text-font">
              {llmRes && extractTextFromHTML(llmRes)}
            </p>
          </div>
        </div>
      </div>

      {/* Search Result Section */}
      <div className="bg-background p-4 h-72 ">
        <div className="bg-white   w-full p-2    rounded-sm">
          <table className="w-full mx-1 shadow-sm">
            <thead className="">
              <tr className="flex  justify-between items-between bg-silver bg-opacity-50">
                {table_header.map((e, index) => {
                  return (
                    <th
                      key={index}
                      className={`text-left font-times font-bold text-xl  p-2`}
                    >
                      <div
                        className={`flex flex-row items-center justify-start  text-black`}
                        onClick={() => setactiveIndex(index)}
                      >
                        {e.name}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            {/* <ErrorBoundary fallback={<p>Something went wrong</p>}> */}
            <ResultTable />
            {/* </ErrorBoundary> */}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Search;
