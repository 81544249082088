import { React, useEffect, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { useTranslation } from 'react-i18next';
import { convertBackToDate } from '../application/protocols/services/utils';
import { useAppContext } from '../application/stores/AppContext';
import { useProfileContext } from '../application/stores/ProfileContext';
import logo from '../assets/images/logo_new_0_23_22_5_2024.png';
import CircleAvatar from '../components/CircleAvatar';
import CustomButton from '../components/CustomButton';
import CustomTextField from '../components/CustomTextField';
import ImageUploadButton from '../components/ImageUploadButton';
import LoadingButton from '../components/LoadingButton';

const Profile = () => {
  const { i18n, t } = useTranslation('global');

  const {
    profile,
    updateProfile,
    updateProfileImage,
    loading,
    changeLanguage,
    getProfile,
  } = useProfileContext();

  const { setLabel, language, setLanguage } = useAppContext();

  const languages = [
    { name: 'English', value: 'en', country: 'US' },
    { name: 'French', value: 'fr', country: 'FR' },
    { name: 'Germany', value: 'de', country: 'DE' },
    // { name: 'Nepali', value: 'np' },
  ];

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    username: '',
  });

  const handleLanguageChange = async (language) => {
    i18n.changeLanguage(language);
    setLanguage(language);
    await changeLanguage(language);
    await getProfile();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(() => ({
      ...formData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormData({
      first_name: profile.first_name ?? '',
      last_name: profile.last_name ?? '',
      username: profile.user?.email ?? '',
    });
    setLabel('Profile');
    i18n.changeLanguage(profile?.language ?? 'en');
    changeLanguage(profile?.language ?? 'en');
    setLanguage(profile?.language ?? 'en');
  }, [profile]);

  if (loading) {
    return <LoadingButton />;
  } else {
    return (
      <div className="bg-background px-4 min-h-[83vh] max-h-[83vh] max-w-full w-full flex-col">
        {loading && <LoadingButton />}
        {
          <div className="bg-white  rounded-xl min-h-[83vh] max-h-[83vh] max-w-full w-full flex-col">
            <div className="flex flex-row justify-start w-full items-start">
              <div className="m-4 rounded-xl w-full">
                <div className="w-full flex justify-between">
                  <h1 className="p-6 text-font font-bold  text-xl font-times">
                    {t('profile.account_details')}
                  </h1>
                  <ReactFlagsSelect
                    onSelect={async (code) => {
                      const lanData = languages.filter(
                        (data) => data.country == code
                      );
                      await handleLanguageChange(lanData[0].value);
                    }}
                    countries={['US', 'DE', 'FR']}
                    selected={(() => {
                      const lanData = languages.filter(
                        (data) => data.value == language ?? 'en'
                      );
                      const country = lanData[0].country;
                      return country;
                    })()}
                    className="m-6 font-times bg-white min-h-[46px] max-h-[46px] h-[46px] border border-fs rounded-md pb-0"
                    customLabels={{
                      US: 'English',
                      FR: 'French',
                      DE: 'Germany',
                    }}
                  />
                  {/* <select
                    onChange={handleLanguageChange}
                    className="m-6 font-times bg-white border border-fs rounded-md px-6"
                  >
                    {languages.map((language, index) => (
                      <option
                        key={index}
                        value={language.value}
                        selected={language.value == profile.language}
                      >
                        {language.name}
                      </option>
                    ))}
                  </select> */}
                </div>
                <div className="border border-dashed border-spacing-2 bg-white h-30 w-auto mx-6 p-4 ">
                  <div className="flex flex-row justify-between items-center">
                    <CircleAvatar image={profile.profile_image ?? logo} />
                    <div className="flex flex-col justify-start items-start ml-2  ">
                      <h1 className=" text-font font-bold text-xl font-times">
                        {profile.first_name ?? ''} {profile.last_name ?? ''}
                      </h1>
                      <h1 className=" text-font text-md font-times">
                        {profile.email}
                      </h1>
                      <h1 className=" text-font text-md font-times">
                        {t('profile.date_joined')}:{' '}
                        {convertBackToDate(profile.date_created)}
                      </h1>
                    </div>
                    <div className="h-55">
                      <ImageUploadButton
                        label={t('buttons.browse')}
                        onImageSelect={updateProfileImage}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col bg-white h-auto w-auto my-4 p-4 mx-2">
                  <CustomTextField
                    name={'first_name'}
                    label={`${t('profile.first_name')}*`}
                    hintText={formData?.first_name ?? ''}
                    value={formData?.first_name || ''}
                    onChange={(ev) => handleInputChange(ev)}
                  />
                  <CustomTextField
                    name={'last_name'}
                    label={`${t('profile.last_name')}*`}
                    hintText={formData?.last_name ?? ''}
                    value={formData?.last_name || ''}
                    onChange={(ev) => handleInputChange(ev)}
                  />
                  <CustomTextField
                    disabled
                    name={'username'}
                    label={`${t('profile.username')}*`}
                    hintText={formData?.username ?? ''}
                    value={formData?.username || ''}
                    onChange={(ev) => handleInputChange(ev)}
                  />
                  <CustomButton
                    label={t('buttons.update')}
                    onClick={() => updateProfile(formData)}
                  />
                  <div className="flex py-8   justify-center">
                    <a
                      href="/forgot-password"
                      className="px-1  font-times font-inter text-font font-bold"
                    >
                      {' '}
                      {t('buttons.change_password')}?
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
};

export default Profile;
