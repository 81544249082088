import React from "react";

const Switch = ({ isActive, label, onChange }) => {
  return (
    <div className="flex flex-row items-center justify-around m-4">
      <label htmlFor="Product" className="mr-4 font-bold text-font text-xl">
        {label ?? "Intelligent Search"}
      </label>
      <label className=" relative inline-flex items-center mb-4 ms-auto my-3  cursor-pointer">
        <input
          type="checkbox"
          value=""
          checked={isActive}
          onChange={onChange}
          className="sr-only peer"
          readOnly
        />
        <div
          className={`w-10 h-6 bg-gray rounded-full peer peer-focus:ring-0 ${isActive ? "peer-focus:ring-primary" : "peer-focus:ring-primary"} dark:peer-focus:ring-primary dark:bg-gray peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray peer-checked:bg-primary`}
        ></div>
      </label>
    </div>
  );
};

export default Switch;
