import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  LocalStorageService,
  LocalStorageServiceItems,
} from '../application/storages/storage_services';
import { useAppContext } from '../application/stores/AppContext';
import { useChatContext } from '../application/stores/ChatContext';
import { useMarketContext } from '../application/stores/MarketContext';
import { useProfileContext } from '../application/stores/ProfileContext';
import logo from '../assets/images/logo_new_0_23_22_5_2024.png';
import CircleAvatar from '../components/CircleAvatar';
import LoadingButton from '../components/LoadingButton';
import CustomSearchField from './CustomSearchField';

const Header = () => {
  const { label } = useAppContext();
  const { headerImage: _headerImage } = useAppContext();
  const { query, handleQueryChange } = useMarketContext();
  const { windowWidth } = useAppContext();
  const navigate = useNavigate();
  const router = useLocation();
  const routes = [
    '/market',
    '/info',
    '/profile',
    '',
    '/',
    '/favorite',
    '/lab',
    '/collection',
  ];
  const { setHeaderImage } = useAppContext();
  const { clearData: clearChatData } = useChatContext();
  const { clearData: clearProfileData } = useProfileContext();
  const { bots } = useMarketContext();
  const { t } = useTranslation('global');

  const menus = [
    {
      title: t('header.menuItems.profile'),
      url: '/profile',
    },
    // {
    //   title: 'Subscription',
    //   url: '/subcription',
    // },
    {
      title: t('header.menuItems.logout'),
      url: '/login',
    },
  ];

  useEffect(() => {
    if (router.pathname.includes('/market/')) {
      const jsonWithImageAddr = bots.filter(
        (bot) =>
          '/market/' +
            encodeURIComponent(bot.redirect_link.substring(8))
              .split('%3A')
              .join(':')
              .split('%2C')
              .join(',')
              .split('%26')
              .join('&') ==
          router.pathname
      )[0];
      setHeaderImage(() => {
        return jsonWithImageAddr
          ? jsonWithImageAddr.image_addr.includes('https://')
            ? jsonWithImageAddr.image_addr
            : 'https://storage.cloud.google.com/nexus_horizon_faxi/card_images' +
              jsonWithImageAddr.image_addr
          : '';
      });
    }
  }, [router.pathname, bots]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { profile, loading } = useProfileContext();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    LocalStorageService.instance.delete(LocalStorageServiceItems.ACCESS_TOKEN);
    LocalStorageService.instance.delete(LocalStorageServiceItems.REFRESH_TOKEN);
    clearChatData();
    clearProfileData();
    navigate('/');
  };

  useEffect(() => {
    if (!loading && !profile) {
      handleLogout();
    }
  }, [loading]);
  // const getProfile = async () => {
  //   setloading(true);
  //   const doc = `
  //   query profile {
  //       profile {
  //         acc_type
  //         company_name
  //         date_created
  //         dob
  //         first_name
  //         id
  //         last_name
  //         user_source
  //         user_type
  //         profile_image
  //         user {
  //           email
  //           id
  //           last_login
  //           created_at
  //           username
  //         }
  //       }
  //     }`;
  //   const res = await HasuraServices.instance.query(doc);
  //   const profileData = res.msg.profile[0];
  //   setprofile(profileData);
  //   setloading(false);
  // };

  // useEffect(() => {
  //   getProfile();
  // }, []);

  return (
    <div className="flex min-w-full w-full max-w-full xl:max-w-[1920px] min-h-[120px] md:max-h-[120px] max-md:flex-col">
      <div className="w-full max-md:py-2 md:w-52 flex items-center justify-between md:justify-center md:min-w-52 md:max-w-52 max-md:border-b max-md:border-gray max-md:border-opacity-10 bg-white">
        <Link to={'/'}>
          <div className=" text-center  md:border-b md:border-gray md:border-opacity-10">
            <img
              src={logo}
              alt="nexuslogo"
              className="w-[104px] min-w-[104px] max-w-[104px] h-[60px] min-h-[60px] max-h-[60px] md:w-52 md:min-w-52 md:max-w-52 md:h-full md:max-h-[120px] object-contain"
            />
          </div>
        </Link>
        <div className={'bg-white rounded-3xl md:hidden'}>
          <div className="flex flex-row justify-center items-center text-xs">
            <h1 className="bg-white rounded-3xl   text-center  mx-2 w-auto pl-4 mr-1">
              {`${t('header.greeting')}, ${profile?.first_name ?? ''} ${profile?.last_name ?? ''}`}
            </h1>
            <div className="relative" onClick={toggleMenu}>
              <CircleAvatar image={profile?.profile_image ?? logo} />
              <div className="relative">
                <div
                  className={`absolute right-0 z-10 w-52 bg-background rounded-lg shadow-lg ${
                    isMenuOpen ? '' : 'hidden'
                  }`}
                >
                  <ul>
                    {menus.map((e, index) => {
                      if (e.url === '/login') {
                        return (
                          <li
                            key={index}
                            onClick={() => handleLogout()}
                            className="py-2 bg-[#F4F7F8] px-4 hover:bg-primary hover:text-white cursor-pointer rounded-md"
                          >
                            {e.title}
                          </li>
                        );
                      } else {
                        return (
                          <Link key={index} to={e.url}>
                            <li className="py-2 bg-[#F4F7F8] px-4 hover:bg-primary hover:text-white cursor-pointer rounded-md">
                              {e.title}
                            </li>
                          </Link>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="text-center p-4">
          <LoadingButton label={t('header.loading_your_profile')} />
        </div>
      )}
      {!loading && (
        <div className="bg-background p-4 w-full max-w-full max-h-full">
          <div className="flex flex-row max-w-full justify-between max-h-full items-center">
            <div className="flex flex-row max-w-full  gap-3">
              {!routes.includes(router.pathname) ? (
                <img
                  src={_headerImage}
                  className="max-h-[60px] max-w-28 md:max-h-[110px] ml-1 object-contain rounded-md my-4 flex"
                />
              ) : windowWidth > 800 ? (
                <div></div>
              ) : (
                <></>
              )}
              {!routes.includes(router.pathname) && (
                <div
                  className={`flex flex-col  md:h-28 justify-center md:justify-between ${windowWidth > 800 ? 'items-center' : 'items-start'}`}
                >
                  {!router.pathname == '/market' ? (
                    <p className="text-3xl mt-4 mb-3">
                      {label ?? 'wwww.faxi.ai'}
                    </p>
                  ) : (
                    <div className="max-md:mt-3 mt-11 mb-3">
                      {label.split('\n\n').map((subLabel, index) => {
                        return (
                          <p
                            key={index}
                            className={` ${subLabel?.length > 20 ? (index < 1 ? 'max-md:text-md text-lg' : 'max-md:text-xs text-md') : index < 1 ? 'max-md:text-md text-lg' : 'max-md:text-xs text-md'}`}
                          >
                            {subLabel ?? 'wwww.faxi.ai'}
                          </p>
                        );
                      })}
                    </div>
                  )}

                  {router.pathname == '/market' && (
                    <CustomSearchField
                      value={query}
                      onChange={handleQueryChange}
                      className={`border-white max-w-full min-w-0 rounded-xl  -pb-0 ${windowWidth > 800 ? 'ml-2' : 'ml-0'}`}
                    />
                  )}
                </div>
              )}
            </div>
            {routes.includes(router.pathname) && (
              <div
                className={`flex flex-col w-full  md:max-w-[60%]  md:h-28 justify-between ${windowWidth > 800 ? 'items-center' : 'items-start'}`}
              >
                {!router.pathname == '/market' ? (
                  <p className="text-sm md:text-3xl mt-4 mb-3">
                    {label ?? 'wwww.faxi.ai'}
                  </p>
                ) : (
                  <p
                    className={`max-md:hidden md:mt-10 md:mb-3 ${label.length > 40 && windowWidth < 1000 ? 'text-xl' : windowWidth < 1400 ? 'text-2xl' : 'text-3xl'}`}
                  >
                    {label ?? 'wwww.faxi.ai'}
                  </p>
                )}

                {router.pathname == '/market' && (
                  <CustomSearchField
                    value={query}
                    hintText={t('store.searchbar.hint_text')}
                    onChange={handleQueryChange}
                    className={`max-md:-mb-4 max-md:text-sm border-white max-w-full w-[400px] min-w-0 rounded-xl  -pb-0 ${windowWidth > 800 ? 'ml-2' : 'ml-0'}`}
                  />
                )}
              </div>
            )}
            <div className="bg-white rounded-3xl max-md:hidden">
              <div className="flex flex-row justify-center items-center">
                <h1 className="bg-white rounded-3xl hidden md:block  text-center  mx-2 w-auto pl-4">
                  {t('header.greeting')},{' '}
                  {`${profile?.first_name ?? ''} ${profile?.last_name ?? ''}`}
                </h1>
                <div className="p-1 relative" onClick={toggleMenu}>
                  <CircleAvatar image={profile?.profile_image ?? logo} />
                  <div className="relative">
                    <div
                      className={`absolute right-0 z-10 w-52 border-[1px] border-opacity-20 bg-background rounded-lg border-[#404747] shadow-lg ${
                        isMenuOpen ? '' : 'hidden'
                      }`}
                    >
                      <ul className="border-[#404747]">
                        {menus.map((e, index) => {
                          if (e.url === '/login') {
                            return (
                              <li
                                key={index}
                                onClick={() => handleLogout()}
                                className="py-2 px-4 bg-[#F0F3FA]  hover:bg-primary hover:text-white cursor-pointer rounded-md"
                              >
                                {e.title}
                              </li>
                            );
                          } else {
                            return (
                              <Link key={index} to={e.url}>
                                <li className="py-2 px-4 bg-[#F0F3FA]  hover:bg-primary hover:text-white cursor-pointer rounded-md">
                                  {e.title}
                                </li>
                              </Link>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
