import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          width: '100%',
          mr: 1,
          borderRadius: '10px',
        }}
      >
        <LinearProgress
          sx={{
            height: '20px',
            borderRadius: '10px',
            color: '#521e95',
            bgColor: '#521e95',
          }}
          color="inherit"
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >{`${Math.ceil(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function LinearWithValueLabel({ progress, setProgress }) {
  React.useEffect(() => {
    const timer = setInterval(() => {
      setTimeout(
        () =>
          setProgress((prevProgress) => {
            if (prevProgress >= 100) {
              clearInterval(timer);
              return prevProgress;
            } else {
              const newProgressStatus =
                prevProgress + Math.ceil(Math.random() * 2);
              return newProgressStatus > 100 ? 100 : newProgressStatus;
            }
          }),
        Math.ceil(Math.random() * 500)
      );
    }, 300);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
