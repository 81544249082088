import DiamondSharpIcon from '@mui/icons-material/DiamondSharp';
import { useTranslation } from 'react-i18next';
import { BsActivity, BsInfoCircle, BsStar } from 'react-icons/bs';
import InventoryIcon from '@mui/icons-material/Inventory';
import { Link, useLocation } from 'react-router-dom';
import { useAppContext } from '../application/stores/AppContext';
import InnovationLabPage from '../pages/InnovationLabPage';
import Favourite from '../pages/FavouritePage';
import Info from '../pages/Info';
import Store from '../pages/Store';

// const CustomStoreIcon = ({ size, color }) => {
//   return (
//     <img
//       src={CustomIcon}
//       alt="Custom Icon"
//       style={{ width: size, height: "auto", fill: color }} // Adjust styles as needed
//     />
//   );
// };

const Sidebar = () => {
  const { windowWidth, windowHeight } = useAppContext();
  const [t] = useTranslation('global');

  const menus = [
    {
      name: t('buttons.store'),
      icon: BsActivity,
      route: '/market',
      screen: Store,
    },
    {
      name: t('buttons.favourite'),
      icon: BsStar,
      route: '/favorite',
      screen: Favourite,
    },
    {
      name: t('buttons.collection'),
      icon: InventoryIcon,
      route: '/collection',
      screen: InnovationLabPage,
    },
    {
      name: t('buttons.lab'),
      icon: DiamondSharpIcon,
      route: '/lab',
      screen: InnovationLabPage,
    },
    // {
    //   name: "Profile",
    //   icon: BsPerson,
    //   route: "/profile",
    //   screen: Profile,
    // },
    {
      name: t('buttons.info'),
      icon: BsInfoCircle,
      route: '/info',
      screen: Info,
    },
    //   {
    //     name: "Search",
    //     icon: BsSearch,
    //     route: "/search",
    //     screen: Search,
    //   },
    // {
    //     name: "Report",
    //     icon: BsReceipt,
    //     route: '/report',
    //     screen: Dashboard,
    // },
    //   {
    //     name: "Favourite",
    //     icon: BsStar,
    //     route: "/favourite",
    //     screen: Favourite,
    //   },
    //   {
    //     name: "History",
    //     icon: FaHistory,
    //     route: "/history",
    //     screen: History,
    //   },
    //   {
    //     name: "Documents",
    //     icon: BsFillFileEarmarkPdfFill,
    //     route: "/documents",
    //     screen: Documents,
    //   },
    //   {
    //     name: "Upload",
    //     icon: FiUploadCloud,
    //     route: "/documents/upload",
    //     screen: Documents,
    //   },
  ];
  const location = useLocation();
  const desiredHeight = windowHeight - 120;
  return (
    <div
      style={{ height: desiredHeight }}
      className={`${
        windowWidth > 500 ? '' : 'hidden'
      } h-full min-h-full flex flex-col justify-between   items-center w-52 min-w-52 max-w-52`}
    >
      <div
        className="w-full mt-8 flex flex-col gap-2
      "
      >
        {menus.map((e, index) => {
          const active =
            location.pathname.includes(e.route) &&
            !location.pathname.includes(e.route + '/');
          const Icon = e.icon;
          return (
            <Link to={e.route} className="w-full" key={index + 1}>
              <div
                className={`flex gap-1 flex-row justify-start items-center p-3 mx-4  rounded-xl ${
                  active
                    ? 'bg-primary'
                    : 'bg-white hover:bg-silver hover:bg-opacity-35'
                }`}
              >
                <Icon
                  color={active ? 'white' : 'black'}
                  size={20}
                  htmlColor={active ? '#fff' : '#000'}
                />
                <p
                  className={`text-xl ${
                    active ? 'text-background mx-2' : 'text-black mx-2'
                  }`}
                >
                  {e.name}
                </p>
              </div>
            </Link>
          );
        })}
      </div>

      <div className="flex flex-col gap-2 justify-center items-center text-xs mb-3">
        <Link to="mailto:contact@nexushorizon.fr" className="font-semibold">
          contact@nexushorizon.fr
        </Link>
        <Link
          to="https://nexushorizon.fr/privacy-policy"
          target="_blank"
          className="font-semibold text-primary"
        >
          Privacy Policy
        </Link>
        <Link
          to="https://nexushorizon.fr/terms-and-conditions"
          target="_blank"
          className="font-semibold text-primary"
        >
          Terms & Conditions
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
