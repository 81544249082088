const LoadingButton = ({ label, className, bgColor = '#521e95' }) => {
  return (
    <button
      className={` w-full text-center  hover:bg-fs text-white font-semibold py-2 px-4 rounded-md inline-flex items-center space-x-2 ${className}`}
      style={{ backgroundColor: bgColor }}
      type="button"
    >
      {label && <span>{label}</span>}
      <svg
        className="animate-spin h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 6.627 5.373 12 12 12v-4a8 8 0 01-6-2.709z"
        ></path>
      </svg>
    </button>
  );
};

export default LoadingButton;
