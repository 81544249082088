import { jwtDecode } from 'jwt-decode';
import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  LocalStorageService,
  LocalStorageServiceItems,
} from '../application/storages/storage_services';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = LocalStorageService.instance.get(
    LocalStorageServiceItems.ACCESS_TOKEN
  );
  const hasToken = token !== null && token !== undefined && token !== '';
  if (hasToken) {
    const decoded = jwtDecode(token);
    const expirationTime = decoded.exp;
    const isTokenExpired = expirationTime < Math.floor(Date.now() / 1000);
    const validToken = !isTokenExpired;
    if (isTokenExpired) {
      LocalStorageService.instance.delete(
        LocalStorageServiceItems.ACCESS_TOKEN
      );
    }
    return validToken ? <Component {...rest} /> : <Navigate to="/login" />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
