import React, { createContext, useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { generateAlphanumericString } from '../protocols/guard/guard';
import { HasuraServices } from '../protocols/services/api_services';
import { useMarketContext } from './MarketContext';
import { useProfileContext } from './ProfileContext';

const InnovationContext = createContext();

export const InnovationProvider = ({ children }) => {
  const { userId } = useProfileContext();
  const [createdCardsList, setCreatedCardsList] = useState([]);
  const { forceFetchBots } = useMarketContext();
  const [progress, setProgress] = useState(2);
  const [loading, setLoading] = useState(false);

  const getCreatedCardList = () => {
    const getCreatedCardsQuery = `query GetCardInformation($user_id:uuid!){
    cards(where:{user_id:{_eq:$user_id}}){
        frontend_config{
        config_main
        }
    }
}`;

    const getCreatedCardsVariables = {
      user_id: userId,
    };

    HasuraServices.instance
      .query(getCreatedCardsQuery, getCreatedCardsVariables)
      .then((res) => {
        const list = res.msg?.cards?.map(
          (card) => card.frontend_config[0].config_main
        );
        setCreatedCardsList(
          list?.map((cardData) => ({
            ...cardData,
            image_addr: `https://storage.cloud.google.com/nexus_horizon_faxi/card_images${cardData.image_addr}`,
            actionAreaKey: generateAlphanumericString(4),
          }))
        );
      });
  };

  const deleteCreatedCard = (bot) => {
    const query = `mutation MyMutation($card_name:String!){
      delete_card(args:{card_name:$card_name}){
        message
        status
      }
    }`;
    const variables = {
      card_name: bot.display_name
        .toLowerCase()
        .split(' ')
        .join('_')
        .split('-')
        .join('_'),
    };
    HasuraServices.instance.query(query, variables).then(async (res) => {
      if (
        res.msg.delete_card.status == '200' &&
        res.msg.delete_card.message == 'Card deleted successfully'
      ) {
        setTimeout(async () => {
          setLoading(false);
          setProgress(2);
          await Swal.fire({
            icon: 'success',
            text: 'Card deleted successfully.',
            confirmButtonColor: '#521E95',
            footer:
              'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
          });

          await forceFetchBots();
          setProgress(0);
          getCreatedCardList();
        }, 3000);
      } else {
        setLoading(false);
        setProgress(0);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Couldn't delete your card. Please try again after sometime.`,
          footer:
            'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
        });
      }
    });
  };

  useEffect(() => {
    if (userId) {
      getCreatedCardList();
    }
  }, [userId]);

  return (
    <InnovationContext.Provider
      value={{
        createdCardsList,
        deleteCreatedCard,
        progress,
        setProgress,
        loading,
        setLoading,
      }}
    >
      {children}
    </InnovationContext.Provider>
  );
};

export const useInnovationContext = () => {
  return useContext(InnovationContext);
};
