import {
  LocalStorageService,
  LocalStorageServiceItems,
} from '../../storages/storage_services';

function errorLogger(variables, error) {
  var _errorString = `
         Base URL: ${error}
        Variables: $variables
        Encoded Variables: ${JSON.stringify(variables)}
        Error: ${error}
        Error Message: ${error.message}
        `;
  console.error(_errorString);
}

function successLogger(variables, response) {
  var _successString = `
    Base URL: ${response.headers}
    Variables: ${variables}
    Encoded Variables: ${JSON.stringify(variables)}
    Status: ${response.status}
    Data: ${response.data}
   `;
  console.log(_successString);
}

class ApiResponse {
  status;
  msg;
  constructor(status, msg) {
    this.status = status;
    this.msg = msg;
  }

  toString() {
    return `Status: ${this.status}, Msg: ${this.msg}`;
  }
}

// var base_url = 'http://localhost:8000';
// var base_url = 'https://api.morwears.com';

class ApiBaseModel {
  //Axios client declaration
  axios;
  base_url;
  static instance;

  constructor(axios, base_url) {
    this.axios = axios;
    this.base_url = base_url;
    // axios.interceptors.request.use(
    //   async (config) => {
    //     const token = LocalStorageService.instance.get(
    //       LocalStorageServiceItems.ACCESS_TOKEN
    //     );
    //     if (token) {
    //       config.headers.Authorization = `Bearer ${token}`;
    //       config.headers['x-hasura-role'] = 'client';
    //     }
    //     return config;
    //   },
    //   (error) => {
    //     return Promise.reject(error);
    //   }
    // );
  }

  async get(url, variables) {
    try {
      const response = this.axios({
        method: 'get',
        url: `${this.base_url}${url}`,
        data: variables,
      });
      let res = await response;
      successLogger(variables, response);
      return new ApiResponse(true, res.data);
    } catch (error) {
      errorLogger(variables, error);
      return new ApiResponse(false, error);
    }
  }

  async post(url, variables) {
    let data = JSON.stringify(variables);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${this.base_url}${url}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };
    this.axios
      .request(config)
      .then((response) => {
        successLogger(variables, response);
        return new ApiResponse(true, response.data);
      })
      .catch((error) => {
        console.log(error);
        errorLogger(variables, error);
        return new ApiResponse(false, error);
      });
    //     try {
    //     console.log(this.base_url);
    //     const response = this.axios({
    //         method: 'post',
    //         url: `${this.base_url}${url}`,
    //         data: variables
    //     });
    //     let res = await response
    //     successLogger(variables, response);
    //     return new ApiResponse(true, res.data);
    // } catch (error) {
    //     errorLogger(variables, error);
    //     return new ApiResponse(false, error);
    // }
  }

  async put(url, variables) {
    try {
      const response = this.axios({
        method: 'put',
        url: `${this.base_url}${url}`,
        data: variables,
      });
      let res = await response;
      successLogger(variables, response);
      return new ApiResponse(true, res.data);
    } catch (error) {
      errorLogger(variables, error);
      return new ApiResponse(false, error);
    }
  }

  async patch(url, variables) {
    try {
      const response = this.axios({
        method: 'patch',
        url: `${this.base_url}${url}`,
        data: variables,
      });
      let res = await response;
      successLogger(variables, response);
      return new ApiResponse(true, res.data);
    } catch (error) {
      errorLogger(variables, error);
      return new ApiResponse(false, error);
    }
  }

  async delete(url, variables) {
    try {
      const response = this.axios({
        method: 'delete',
        url: `${this.base_url}${url}`,
        data: variables,
      });
      let res = await response;
      successLogger(variables, response);
      return new ApiResponse(true, res.data);
    } catch (error) {
      errorLogger(variables, error);
      return new ApiResponse(false, error);
    }
  }
}

const hasura_url = process.env.REACT_APP_HASURA_URL;

class HasuraBaseServices {
  //  Needs Implementation
  //   delete(arg0: string, arg1: {}) {
  //     throw new Error("Method not implemented.");
  //   }

  //Axios client declaration
  axios;
  static instance;

  constructor(axios) {
    this.axios = axios;
  }

  async mutation({ updateMutation, data }) {
    try {
      const headers = {
        'Content-Type': 'application/json',
      };
      if (
        LocalStorageService.instance.get(LocalStorageServiceItems.ACCESS_TOKEN)
      ) {
        headers.Authorization = `Bearer ${LocalStorageService.instance.get(
          LocalStorageServiceItems.ACCESS_TOKEN
        )}`;
        headers['X-Hasura-Role'] = 'client';
      }
      const response = this.axios({
        method: 'post',
        url: hasura_url,
        headers: headers,
        data: JSON.stringify({
          query: updateMutation,
          variables: data,
        }),
      });
      let res = await response;
      successLogger(updateMutation, response);
      return new ApiResponse(true, res.data.data);
    } catch (error) {
      errorLogger(updateMutation, error);
      return new ApiResponse(false, error);
    }
  }

  async query(query, variables, role) {
    try {
      const data = {
        query: query,
      };
      if (variables) {
        data.variables = variables;
      }
      const headers = {
        'Content-Type': 'application/json',
      };
      if (
        LocalStorageService.instance.get(LocalStorageServiceItems.ACCESS_TOKEN)
      ) {
        headers.Authorization = `Bearer ${LocalStorageService.instance.get(
          LocalStorageServiceItems.ACCESS_TOKEN
        )}`;
        headers['X-Hasura-Role'] = role;
      }
      const response = this.axios({
        method: 'post',
        url: hasura_url,
        headers: headers,
        data: JSON.stringify(data),
      });
      let res = await response;
      successLogger(query, response);
      return new ApiResponse(true, res.data.data);
    } catch (error) {
      errorLogger(query, error);
      return new ApiResponse(false, error);
    }
  }
}

class AxiosTokenInterceptor {
  static error() {
    throw new Error('Method not implemented.');
  }
  static response() {
    throw new Error('Method not implemented.');
  }
  static request(config) {
    return async (resolve) => {
      config.headers = { ...config.headers, ...(await _getHeaders()) };
      resolve(config);
    };
  }
  _infoLogger(options) {
    console.log(`
      URL: ${options.url}
      METHOD: ${options.method}
      VALIDATE STATUS: ${options.validateStatus}
      Headers: ${options.headers}
      Follow Redirects: ${options.followRedirects}
      Extra: ${options.extra}
      List Format: ${options.listFormat}

      Query Parameters: ${options.params}
    `);
  }

  async request(config) {
    config.headers = { ...config.headers, ...(await _getHeaders()) };
    this._infoLogger(config);
    return config;
  }

  response(response) {
    return response;
  }

  error(error) {
    return Promise.reject(error);
  }
}

async function _getHeaders() {
  const _token = LocalStorageService.instance.get(
    LocalStorageServiceItems.ACCESS_TOKEN
  );
  let _headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Connection: 'keep-alive',
    // "Cookie": "csrftoken=89AOo8IBWB2UIWnxtXL7G8KMkcJxl3VvnzjFeCNxNs1KDQ4BmhzEHxzmbwtgBfFf; sessionid=ym3ta1erjkcd8nbxilt5ka2bfc958yqt",
  };
  if (_token != null) {
    _headers['Authorization'] = `Bearer ${_token} `;
  }
  return _headers;
}

export { ApiBaseModel, AxiosTokenInterceptor, HasuraBaseServices, hasura_url };
