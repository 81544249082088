import React, { forwardRef } from 'react';

const CustomButton = forwardRef(
  ({ label, style, onClick, type, ...props }, ref) => {
    return (
      <div className="w-full h-10 rounded-xl">
        <button
          type={type}
          onClick={onClick}
          className={
            style ??
            ' bg-primary w-full h-10 text-background font-bold py-2 px-6 rounded-md hover:bg-blue-600 focus:ring focus:ring-blue-200'
          }
          ref={ref}
          {...props}
        >
          {label}
        </button>
      </div>
    );
  }
);

export default CustomButton;
