import React, { createContext, useContext, useEffect, useState } from 'react';
import { generateAlphanumericString } from '../protocols/guard/guard';
import { HasuraServices } from '../protocols/services/api_services';
import { useAppContext } from './AppContext';

const CollectionContext = createContext();

export const CollectionCardsProvider = ({ children }) => {
  const { language } = useAppContext();
  const [query, setQuery] = useState('');
  const [originals, setOriginals] = useState([]);
  const [fetchedOriginalData, setFetchedOriginalData] = useState({
    en: null,
    de: null,
    fr: null,
  });

  const [bots, setBots] = useState(originals);

  const fetchBots = async () => {
    const query = `query MyQUeryasdf($language:languages_enum!){
    frontend_card_config(where:{language:{_eq: $language}}){
      card_id
      config_info
      config_main
    }
  }`;
    const variables = {
      language: language,
    };
    if (!fetchedOriginalData[language]) {
      HasuraServices.instance.query(query, variables, 'premium').then((res) => {
        if (res.msg) {
          const generatedOriginals = res.msg?.frontend_card_config
            .map((card_config_info) => card_config_info.config_main)
            .map((cardData) => ({
              ...cardData,
              image_addr: `https://storage.cloud.google.com/nexus_horizon_faxi/card_images${cardData.image_addr}`,
              actionAreaKey: generateAlphanumericString(4),
            }));
          setOriginals(generatedOriginals);
          setFetchedOriginalData({
            ...fetchedOriginalData,
            [language]: generatedOriginals,
          });
        }
      });
    } else {
      setOriginals(fetchedOriginalData[language]);
    }
  };

  const forceFetchBots = async () => {
    const query = `query MyQUeryHerer($language:languages_enum!){
    frontend_card_config(where:{language:{_eq: $language}}){
      card_id
      config_info
      config_main
    }
  }`;
    const variables = {
      language: language,
    };
    HasuraServices.instance.query(query, variables).then((res) => {
      const generatedOriginals = res.msg?.frontend_card_config
        .map((card_config_info) => card_config_info.config_main)
        .map((cardData) => ({
          ...cardData,
          image_addr: `https://storage.cloud.google.com/nexus_horizon_faxi/card_images${cardData.image_addr}`,
          actionAreaKey: generateAlphanumericString(4),
        }));
      setOriginals(generatedOriginals);
      setFetchedOriginalData({
        ...fetchedOriginalData,
        [language]: generatedOriginals,
      });
    });
  };

  useEffect(() => {
    fetchBots();
  }, [language]);

  useEffect(() => setBots(originals), [originals]);

  const rateBots = () => {
    let ratedBots = [];
    let tempBots = originals;
    const addRate = originals.length;
    tempBots.forEach((bot) => {
      let character = '';
      let shortDescription = bot.short_description.toLowerCase();
      let detailedDescription = bot.detailed_description.toLowerCase();
      let displayName = bot.display_name.toLowerCase();
      for (let index = 0; index < query.length; index++) {
        character = query.charAt(index);
        if (displayName.includes(character)) {
          bot.rate = bot.rate + addRate * 4;
        }
        if (shortDescription.includes(character)) {
          bot.rate = bot.rate + addRate * 2;
        }
        if (detailedDescription.includes(character)) {
          bot.rate = bot.rate + addRate * 1;
        }
      }
      if (displayName.includes(query)) {
        bot.rate = bot.rate + addRate * 4 * query.length;
      }
      if (shortDescription.includes(query)) {
        bot.rate = bot.rate + addRate * 2 * query.length;
      }
      if (detailedDescription.includes(query)) {
        bot.rate = bot.rate + addRate * 1 * query.length;
      }
      ratedBots.push(bot);
    });
    setBots(ratedBots);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    if (query == '') {
      setBots(originals);
    } else {
      rateBots();
    }
  }, [query]);

  return (
    <CollectionContext.Provider
      value={{
        query,
        setQuery,
        bots,
        fetchBots,
        forceFetchBots,
        handleQueryChange,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};

export const useCollectionContext = () => {
  return useContext(CollectionContext);
};
