import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAppContext } from '../../application/stores/AppContext';
import { useChatContext } from '../../application/stores/ChatContext';

const DropZone = ({ children, ...props }) => {
  const [dropZoneWidth, setDropZoneWidth] = useState(
    'absolute text-2xl text-black text-opacity-55 top-0 left-0 w-[500px] min-h-full flex justify-center items-center text-center border-8 border-dashed border-background'
  );
  const { imageChangeFromDropZone } = useChatContext();
  const ref = useRef(null);
  const { windowWidth } = useAppContext();
  const [showDropzone, setShowDropzone] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const handleInputChange = () => {
    clearTimeout(debounceTimeout);
    setShowDropzone(true);
    setDebounceTimeout(
      setTimeout(() => {
        setShowDropzone(false);
      }, 500) // Adjust the delay as needed (e.g., 500 milliseconds)
    );
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Handle file upload here
    imageChangeFromDropZone(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
  });

  const handleClick = (event) => {
    // Prevent click only if it's on the dropzone itself, not on its children
    if (event.target === event.currentTarget) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (ref.current) {
      let x = ref.current.getBoundingClientRect().width - 270 - 80;
      setDropZoneWidth(
        `absolute text-2xl text-black text-opacity-55 top-[68px] left-[72px] min-w-[88%] w-[${x}px] min-h-[94px] max-h-[94px] flex justify-center items-center text-center border-8 border-dashed border-background`
      );
    }
  }, [windowWidth]);

  return (
    <div
      {...getRootProps()}
      onClick={handleClick}
      {...props}
      style={{ position: 'relative' }}
      id="dropzone-custom"
      ref={ref}
      onDragOver={handleInputChange}
    >
      <input {...getInputProps()} />
      {showDropzone ? (
        <p className={dropZoneWidth} style={styles.dropzone}>
          Drop the files here ...
        </p>
      ) : (
        <></>
      )}
      {children}
    </div>
  );
};

const styles = {
  dropzone: {
    borderRadius: '4px',
    textAlign: 'center',
    cursor: 'pointer',
  },
};

export default DropZone;
