import React, { createContext, useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  LocalStorageService,
  LocalStorageServiceItems,
} from '../storages/storage_services';
import { useProfileContext } from './ProfileContext';
import { useAppContext } from './AppContext';

const ChatSessionContext = createContext();

export const ChatSessionProvider = ({ children }) => {
  const { websocket_url } = useAppContext();
  const [url, setUrl] = useState('');
  const [isWebsocketON, setIsWebsocketON] = useState(false);
  const [messageToSend, setMessageToSend] = useState();
  const [receivedMessage, setReceivedMessage] = useState({
    message: '',
    response: '',
    status: '',
  });
  // const WebSocket = require('ws');
  const { getProfile, profile } = useProfileContext();
  const [ws, setWs] = useState(null);

  useEffect(() => {
    if (messageToSend) {
      if (ws) {
        if (ws.readyState !== 1) {
          startWebSocket();
        } else {
          if (ws.readyState == 1) {
            try {
              ws.send(JSON.stringify(messageToSend));
              setTimeout(() => setMessageToSend(), 1000);
            } catch (e) {
              console.log(e);
            }
          }
        }
      } else {
        startWebSocket();
      }
    }
  }, [messageToSend, ws]);

  function startWebSocket() {
    if (ws) {
      ws.close();
    }
    const websocket = new WebSocket(url);
    setTimeout(() => setWs(websocket), 8000);
  }

  useEffect(() => {
    if (ws) {
      ws.onopen = () => {
        console.log('Connected to the server');
        setIsWebsocketON(true);
      };

      ws.onmessage = async (data) => {
        if (data.data.response == '500 Internal error encountered.') {
          // there was some error in the connection so we close it
          ws.close();
        }
        let actualData = JSON.parse(data.data);
        if (actualData.status == 'Failed') {
          actualData.response =
            'Something went wrong. For more information, email us at contact@nexushorizon.fr';
        }
        if (actualData.response == 'knock knock from the backend') {
          if (!ws || ws.readyState !== 1) {
            startWebSocket();
          }
          let interval = setInterval(async () => {
            if (ws.readyState == 1) {
              try {
                await ws.send('knock knock from the frontend');
              } catch (e) {
                console.log(e);
              }
              clearInterval(interval);
            }
          }, 300);
        } else if (
          actualData.message == 'Quota limit exceeded for trial user'
        ) {
          Swal.fire({
            icon: 'error',
            title: '',
            text: `You have exceeded your quota of 10 queries.`,
            footer:
              'For more information or to subscribe, please email us at <b><a href="mailto:contact@nexushorizon.fr">contact@nexushorizon.fr</a></b> and visit our website at <b><a target=”_blank” href="https://nexushorizon.fr">nexushorizon.fr</a></b>.',
          });
          actualData.response =
            'Something went wrong. For more information, email us at contact@nexushorizon.fr';
          setReceivedMessage(actualData);
        } else if (
          actualData.message == 'User has not activated their account yet.'
        ) {
          getProfile();
        } else {
          setReceivedMessage(actualData);
        }
      };

      ws.onerror = (err) => {
        console.log(ws.readyState);
        console.log(err);
        setReceivedMessage({
          message: '',
          response: '',
          status: '',
        });
        console.error(`WebSocket error: ${err.message}`);
        setIsWebsocketON(false);
      };
      ws.onclose = function () {
        setReceivedMessage({
          message: '',
          response: '',
          status: '',
        });
        console.log(ws.readyState);
        console.log('Disconnected from the server');
        setIsWebsocketON(false);
      };
    }
  }, [ws]);

  const sendMessage = (args) => {
    setMessageToSend(args);
  };

  useEffect(() => {
    if (profile?.user) {
      setUrl(
        `${websocket_url}${profile.user.id}/${String(
          LocalStorageService.instance.get(
            LocalStorageServiceItems.ACCESS_TOKEN
          )
        )}`
      );
    }
  }, [profile]);

  return (
    <ChatSessionContext.Provider
      value={{ sendMessage, receivedMessage, isWebsocketON }}
    >
      {children}
    </ChatSessionContext.Provider>
  );
};

export const useChatSessionContext = () => {
  return useContext(ChatSessionContext);
};
