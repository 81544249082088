import React from 'react';

const CustomTextField = ({
  value,
  name,
  type,
  label,
  hintText,
  onChange,
  ...props
}) => {
  return (
    <div>
      <div className="flex flex-col font-times text-font my-2">
        <label
          htmlFor="textfield"
          className="text-xl font-times text-font mb-2"
        >{`${label}`}</label>
        <input
          type={`${type}`}
          name={name}
          placeholder={hintText}
          value={value != 'undefined' ? value : ''}
          onChange={onChange}
          className="w-max-full p-2 font-times  border border-fs  rounded-md hover:border-primary mb-4 w-full"
          {...props}
        />
      </div>
    </div>
  );
};

export default CustomTextField;
