import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import App from './App';
import './index.css';
import cards_de from './translations/de/cards.json';
import global_de from './translations/de/global.json';
import info_de from './translations/de/info.json';
import cards_en from './translations/en/cards.json';
import global_en from './translations/en/global.json';
import info_en from './translations/en/info.json';
import cards_fr from './translations/fr/cards.json';
import global_fr from './translations/fr/global.json';
import info_fr from './translations/fr/info.json';

i18next
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources: {
      en: {
        global: global_en,
        info: info_en,
        cards: cards_en,
      },
      de: {
        global: global_de,
        cards: cards_de,
        info: info_de,
      },
      fr: {
        global: global_fr,
        cards: cards_fr,
        info: info_fr,
      },
      // np: {
      //   global: global_np,
      //   info: info_np,
      // },
    },
    fallbackLng: 'en',
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
