import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { ChatProvider } from './application/stores/ChatContext';
import { ProfileProvider } from './application/stores/ProfileContext';
import ForgotPassword from './pages/ForgotPassword';
import ForgotPasswordVerify from './pages/ForgotPasswordVerify';
import LoginPage from './pages/Login';
import VerifyOtp from './pages/VerifyOtp';
import ProtectedRoutesWrapper from './views/ProtectedRoutes';

import { AppProvider } from './application/stores/AppContext';
import { ChatSessionProvider } from './application/stores/ChatSessionContext';
import { StoreProvider as MarketProvider } from './application/stores/MarketContext';
import Register from './pages/Register';
import { InnovationProvider } from './application/stores/InnovationContext';
import { CollectionCardsProvider } from './application/stores/CollectionContext';

//We only use one font in the entire project
// We only use three colors, white, purple and the background color.

// import { HasuraServices } from './application/protocols/services/api_services';
// import { LocalStorageService, LocalStorageServiceItems } from './application/storages/storage_services';

// const setAnonymousToken = async () => {
//   const doc = `mutation anonymous {
//     anonymous {
//       access
//       message
//     }
//   }
//   `
//   const res = await HasuraServices.instance.mutation(doc, {});
//   const token = res.msg.data.anonymous.access;
//   LocalStorageService.instance.set(LocalStorageServiceItems.ACCESS_TOKEN,token)
// }

// function App() {
//   // setAnonymousToken()
//   return (
//     <DocumentProvider>
//       <HistoryProvider>
//         <FavouriteProvider>
//           <SearchProvider>
//             <ProfileProvider>
//               <Router>
//                 <Routes>
//                   <Route path="/login" element={<LoginPage />} />
//                   <Route path="/register" element={<Register />} />
//                   <Route path="/verify-otp" element={<VerifyOtp />} />
//                   <Route path="/forgot-password" element={<ForgotPassword />} />
//                   <Route
//                     path="/forgot-password-verify"
//                     element={<ForgotPasswordVerify />}
//                   />
//                   <Route path="/*" element={<ProtectedRoutesWrapper />} />
//                 </Routes>
//               </Router>
//             </ProfileProvider>
//           </SearchProvider>
//         </FavouriteProvider>
//       </HistoryProvider>
//     </DocumentProvider>
//   );
// }

// We will not have Documents, history, search and favourite pages temporarily.
// So we will not be needing SearchProvider, FavouriteProvider, HistoryProvider and DocumentProvider

function App() {
  if (process.env.NODE_ENV === 'production') {
    // Override console methods in production
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
    console.info = () => {};
  }
  return (
    <div className="w-full flex items-center justify-center max-h-screen min-h-screen h-screen ">
      <div className="w-full max-w-full xxl:max-w-[1920px] max-h-screen min-h-screen h-screen ">
        <Router>
          <AppProvider>
            <ProfileProvider>
              <MarketProvider>
                <CollectionCardsProvider>
                  <ChatSessionProvider>
                    <InnovationProvider>
                      <ChatProvider>
                        <Routes>
                          <Route path="/login" element={<LoginPage />} />
                          <Route path="/register" element={<Register />} />
                          <Route path="/verify-otp" element={<VerifyOtp />} />
                          <Route
                            path="/forgot-password"
                            element={<ForgotPassword />}
                          />
                          <Route
                            path="/forgot-password-verify/:email"
                            element={<ForgotPasswordVerify />}
                          />
                          <Route
                            path="/*"
                            element={<ProtectedRoutesWrapper />}
                          />
                        </Routes>
                      </ChatProvider>
                    </InnovationProvider>
                  </ChatSessionProvider>
                </CollectionCardsProvider>
              </MarketProvider>
            </ProfileProvider>
          </AppProvider>
        </Router>
      </div>
    </div>
  );
}

export default App;
