import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye and eye-slash icons from react-icons library

const PasswordField = ({ value, name, label, hintText, onChange }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col font-times text-font my-2">
      <label
        htmlFor="textfield"
        className="text-xl font-times text-font"
      >{`${label}`}</label>
      <div className="relative">
        <input
          type={showPassword ? "text" : "password"}
          name={name}
          placeholder={hintText}
          value={value}
          onChange={onChange}
          className="w-full p-2 font-times border border-fs rounded-md hover:border-primary mb-4"
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 right-0 flex items-center px-3 pb-4 focus:outline-none"
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </button>
      </div>
    </div>
  );
};

export default PasswordField;
