import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';
import {
  BsActivity,
  BsFilePdf,
  BsParagraph,
  BsTextParagraph,
} from 'react-icons/bs';
import { HasuraServices } from '../protocols/services/api_services';
const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const source_header = [
    {
      name: 'Local',
      icon: BsFilePdf,
      route: 'pdf',
    },
    {
      name: 'Global',
      icon: BsParagraph,
      route: 'google',
    },
  ];

  const aiResultTableHeader = [
    {
      name: 'ID',
      icon: BsParagraph,
      route: '',
    },
    {
      name: 'Faxi Assistant',
      icon: BsParagraph,
      route: '',
    },
    {
      name: 'Action',
      icon: BsActivity,
      route: '',
    },
  ];

  const table_header = [
    {
      name: 'ID',
      icon: BsParagraph,
      route: '',
    },
    {
      name: 'Content',
      icon: BsParagraph,
      route: '',
    },
    {
      name: 'Source',
      icon: BsTextParagraph,
      route: '',
    },
    {
      name: 'Action',
      icon: BsActivity,
      route: '',
    },
  ];

  const [activeIndex, setactiveIndex] = useState(0);
  const [loading, setloading] = useState(false);
  // const [profile, setprofile] = useState({});
  const [intl_search, setintl_search] = useState(false);
  const [query, setquery] = useState('');
  const [llmRes, setLlmRes] = useState(null);
  const [results, setresults] = useState([]);
  const [global_search, setglobal_search] = useState(false);
  const [googleResults, setgoogleResults] = useState([]);

  const [toDate, setToDate] = useState(new Date().getTime()); // Current timestamp
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const [fromDate, setFromDate] = useState(sevenDaysAgo.getTime()); // Timestamp 7 days ago

  const [loadingText, setLoadingText] = useState('');

  const [activeViewIndex, setactiveViewIndex] = useState(0);
  // const [wbUrl, setwbUrl] = useState();

  const handleViewChange = async (route) => {
    const index = source_header.findIndex((item) => item.route === route);
    if (index !== -1) {
      setactiveViewIndex(index);
    }
    // You might want to handle the case where the route is not found in the array.
    // This example sets -1 if the route is not found.
    // You can adjust this part based on your specific use case.
    else {
      setactiveViewIndex(-1);
    }
  };

  // Local Search
  const searchInDocs = async () => {
    setloading(true);
    setresults([]);
    //formatting dates to timestamp required by graphql
    const endDate = new Date(toDate);
    const startDate = new Date(fromDate);
    const formattedToDate = endDate.toISOString();
    const formattedFromDate = startDate.toISOString();

    // Local Search
    if (!global_search) {
      // Search documents only when global search is disabled
      var doc = `query MyQuery {
                document(where: {_and: [{text_content: {_is_null: false}}, {name: {_ilike: "%${query}%"}, text_content: {_ilike: "%${query}%"}},{created_at:{_gt:"${formattedFromDate}"}},{created_at:{_lt:"${formattedToDate}"}}]}) {
                    created_at
                    file_url
                    id
                    name
                    favourites {
                        document_id
                        id
                    }
                }
                }
                `;
      const res = await HasuraServices.instance.query(doc);
      const _docs = res.msg.document;
      var items = _docs.map((item) => ({ document_id: item.id, query: query }));
      setresults(_docs);
      await insertDocumentHistories(items);
      setloading(false);
    }
  };
  // End of Local Search

  //Chatbot
  const searchInChatbot = async () => {
    setloading(true);
    //clearing previous values
    setLoadingText('FaxiApp is anaylising....\n');
    setLlmRes('');
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://chatbot.faxi.ai/chat?query=${query}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {},
      };
      axios
        .request(config)
        .then(async (response) => {
          setLlmRes(response.data.response);
          await insertLLMHisotry();
        })
        .catch((error) => {
          console.log(error);
        });
      setloading(false);
    } catch (e) {
      console.log(e);
      setloading(false);
    }
  };
  //Endof Chatbot

  // Google Search
  const searchInGoogle = async (formattedFromDate, formattedToDate) => {
    setloading(true);
    setgoogleResults([]);

    try {
      if (global_search) {
        var searchEnginePayload = {
          query: query,
          intl_search: intl_search,
          from_date: formattedFromDate,
          to_date: formattedToDate,
        };
        let data = JSON.stringify(searchEnginePayload);
        let _config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://search.faxi.ai/search',
          headers: {
            'Content-Type': 'application/json',
          },
          data: data,
        };

        // axios
        //   .request(config)
        //   .then(async (response) => {
        //     console.log(JSON.stringify(response.data));
        //     await insertGoogleSearchHistories(googleResults);
        //     setgoogleResults(response.data.data);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });

        const response = await fetch('./google_res.json'); // Fetch the JSON file
        const datas = await response.json(); // Get JSON data from the response
        setgoogleResults(datas);

        setloading(false);
      }
    } catch (e) {
      console.log(e);
      setloading(false);
    }
  };
  // End of  Google Search

  const handleSearch = async () => {
    //formatting dates to timestamp required by graphql
    const endDate = new Date(toDate);
    const startDate = new Date(fromDate);
    const formattedToDate = endDate.toISOString();
    const formattedFromDate = startDate.toISOString();
    try {
      if (global_search) {
        await searchInGoogle(formattedFromDate, formattedToDate);
      } else {
        await searchInDocs();
      }
      await searchInChatbot(formattedFromDate, formattedToDate);
    } catch (e) {
      setloading(false);
    }
  };

  const handleIntlSearch = async () => {
    setintl_search(!intl_search);
  };

  const handleGlobalSearch = async () => {
    setglobal_search(!global_search);
  };

  const handleFromDateChange = async (val) => {
    setFromDate(val);
  };
  const handleToDateChange = async (val) => {
    setToDate(val);
  };

  // Insert Document to  Histories
  const addToFavourite = async () => {
    if (global_search) {
      // await addGoogleResultToFavourite(e);
    }
  };

  const stopExecution = async () => {
    await HasuraServices.instance.cancelRequest();
    setloading(false);
  };

  // Insert Document to  Histories
  const insertDocumentHistories = async (data) => {
    var mutationData = data
      .map(
        (item) => `{ document_id: ${item.document_id}, query: "${item.query}" }`
      )
      .join(', ');
    console.log(data.length);
    var doc = `
            mutation MyMutation {
                insert_histories(objects: [${mutationData}]) {
                    affected_rows
                    returning {
                        id
                    }
                }
            }
        `;
    await HasuraServices.instance.query(doc);
  };

  // Insert Google Result to  Histories
  // const insertGoogleSearchHistories = async (data) => {
  //   const mutationData = data.map((item) => ({
  //     query: query,
  //     response: item.link,
  //     source: item.title,
  //     content: item.snippet,
  //   }));

  //   const mutationString = mutationData
  //     .map(
  //       (item) =>
  //         ` {source_type: google, query: "${item.query}", response: "${item.response}", source: "${item.source}", content: "${item.content}"}`
  //     )
  //     .join(", ");

  //   const doc = `
  //     mutation MyMutation {
  //       insert_histories(objects: [${mutationString}]) {
  //         affected_rows
  //         returning {
  //           date_created
  //         }
  //       }
  //     }
  //   `;
  //   await HasuraServices.instance.query(doc);
  // };

  /// Insert LLM Result to  Histories
  const insertLLMHisotry = async () => {
    var doc = `
        mutation MyMutation {
          insert_histories(objects: {source_type: faxiassistant, query: "${query}", response: "${llmRes}"}) {
            affected_rows
            returning {
              date_created
            }
          }
        } 
                `;
    await HasuraServices.instance.query(doc);
  };

  const removeDocumentFromFavourite = async (e) => {
    const doc = `mutation MyMutation {
          delete_favourite(where: {document_id: {_eq: ${e.id}}}) {
            affected_rows
            returning {
              id
            }
          }
        } 
          `;
    const res = await HasuraServices.instance.query(doc);
    if (res.status) {
      searchInDocs();
    }
  };

  return (
    <SearchContext.Provider
      value={{
        query,
        addToFavourite,
        removeDocumentFromFavourite,
        setquery,
        stopExecution,
        loadingText,
        llmRes,
        googleResults,
        handleViewChange,
        activeViewIndex,
        source_header,
        intl_search,
        aiResultTableHeader,
        fromDate,
        toDate,
        handleFromDateChange,
        handleToDateChange,
        setactiveIndex,
        global_search,
        handleGlobalSearch,
        results,
        handleIntlSearch,
        activeIndex,
        table_header,
        handleSearch,
        loading,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  return useContext(SearchContext);
};
