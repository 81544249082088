// Next.js API route support: https://nextjs.org/docs/api-routes/introduction

import axios from 'axios';

function isPositiveInteger(number) {
  return Number.isInteger(number) && number > 0 && Math.sign(number) === 1;
}

const validateHeader = (req, res) => {
  const { headers } = req;
  const user_id = get_user_id_from_token(req, res);
  if (!headers.authorization.startsWith('Bearer ')) {
    return res
      .status(401)
      .json({ error: 'Authorization header is not a bearer token.' });
  } else if (!isPositiveInteger(user_id)) {
    return res
      .status(401)
      .json({ error: 'Authorization header is not a valid token.' });
  }
  return true;
};

function get_user_id_from_token(req, res) {
  const token = req;
  const parts = token.split('.');
  if (parts.length !== 3) {
    return res
      .status(400)
      .json({ status: 'Error', data: 'Authorization token not valid.' });
  }
  try {
    const payload = JSON.parse(atob(parts[1]));
    return payload['user_id'];
  } catch (error) {
    return res
      .status(400)
      .json({ status: 'Error', data: 'Error decoding token payload.' });
  }
}

function getUserIdFromToken(token) {
  if (!token) {
    return '';
  }
  const parts = token.split('.');
  if (parts.length !== 3) {
    return '';
  }
  try {
    const payload = JSON.parse(atob(parts[1]));
    return payload['sub'];
  } catch (error) {
    return '';
  }
}

function get_current_role_from_token(req, res) {
  const token = req;
  const parts = token.split('.');
  if (parts.length !== 3) {
    return res
      .status(400)
      .json({ status: `Success`, data: 'Authorization token not valid.' });
  }
  const payload = JSON.parse(Buffer.from(parts[1], 'base64').toString());
  return payload['https://hasura.io/jwt/claims']['x-hasura-default-role'];
}

function generateOTP() {
  const digits = '0123456789';
  let otp = '';
  for (let i = 0; i < 6; i++) {
    otp += digits[Math.floor(Math.random() * 10)];
  }
  return otp;
}

function generateAlphanumericString(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

function getDateTime() {
  const currentDateTime = new Date();
  const options = { hour12: true };
  const formattedDateTime = currentDateTime.toLocaleString('en-US', options);
  return formattedDateTime;
}

function convertDateFormat(originalDateStr) {
  try {
    // Create a new Date object from the original date string
    const originalDate = new Date(originalDateStr);

    // Get individual date components
    const formattedYear = originalDate.getFullYear();
    const formattedMonth = String(originalDate.getMonth() + 1).padStart(2, '0');
    const formattedDay = String(originalDate.getDate()).padStart(2, '0');
    // Construct the formatted date string
    const formattedDateStr = `${formattedYear}-${formattedMonth}-${formattedDay}T00:00:13.565015+00:00`;

    return formattedDateStr;
  } catch (error) {
    return 'Invalid date format. Please provide a valid date string.';
  }
}

function getMailPayload(receiver, subject, content) {
  const mailOptions = {
    from: process.env.EMAIL_HOST_USER,
    to: receiver,
    subject: subject,
    text: content,
    // html: content
  };
  return mailOptions;
}

export {
  axios,
  convertDateFormat,
  generateAlphanumericString,
  generateOTP,
  getDateTime,
  getMailPayload,
  getUserIdFromToken,
  get_current_role_from_token,
  get_user_id_from_token,
  isPositiveInteger,
  validateHeader
};

