import { axios } from '../guard/guard';
import { ApiBaseModel, HasuraBaseServices } from './api_base_services';

const base_url = process.env.REACT_APP_HASURA_URL;

class AxiosServices extends ApiBaseModel {
  axios = axios.create({
    baseURL: base_url,
    timeout: 1000,
  });
  static instance = new ApiBaseModel(axios, base_url);

  constructor() {
    super(axios, base_url);
    if (ApiBaseModel.instance) {
      return ApiBaseModel.instance;
    }
    ApiBaseModel.instance = this;
  }
}

// var searchEngineBaseUrl = 'http://localhost:4000';
var searchEngineBaseUrl = 'https://search.faxi.ai';
class SearchEngineService extends ApiBaseModel {
  axios = axios.create({
    baseURL: searchEngineBaseUrl,
    timeout: 1000,
  });
  static instance = new ApiBaseModel(axios, searchEngineBaseUrl);

  constructor() {
    super(axios, searchEngineBaseUrl);
    if (ApiBaseModel.instance) {
      return ApiBaseModel.instance;
    }
    ApiBaseModel.instance = this;
  }
}

var llmChatBaseUrl =
  'https://c0prlri383.execute-api.eu-west-3.amazonaws.com/default/faxiapp-generate-text';
class LLMChatService extends ApiBaseModel {
  axios = axios.create({
    baseURL: llmChatBaseUrl,
    timeout: 1000,
  });
  static instance = new ApiBaseModel(axios, llmChatBaseUrl);

  constructor() {
    super(axios, llmChatBaseUrl);
    if (ApiBaseModel.instance) {
      return ApiBaseModel.instance;
    }
    ApiBaseModel.instance = this;
  }
}

class HasuraServices extends HasuraBaseServices {
  static instance = new HasuraBaseServices(axios);
  axios = axios.create({
    timeout: 1000,
  });
  constructor() {
    super(axios);
    if (HasuraBaseServices.instance) {
      return HasuraBaseServices.instance;
    }
    HasuraBaseServices.instance = this;
  }
}
export { AxiosServices, HasuraServices, LLMChatService, SearchEngineService };
