import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppContext } from '../application/stores/AppContext';
import { useProfileContext } from '../application/stores/ProfileContext';
import logo from './../assets/images/logo_without_name.png';

const Info = () => {
  const { setLabel, setHeaderImage } = useAppContext();
  const { profile } = useProfileContext();
  const { t } = useTranslation('info');

  useEffect(() => {
    setHeaderImage(logo);
    setLabel('Info Page');

    // getProfile();
  }, []);
  return (
    <div className="bg-background p-4 pb-0 pt-0 w-full h-full min-h-[83vh] max-h-[83vh]">
      <div className="flex flex-col justify-start items-start mx-1 min-h-[83vh] max-h-[83vh] w-full max-w-full  bg-white rounded-lg p-4 md:p-16 md:pb-12 overflow-scroll">
        <p className="text-2xl">
          {`${t('greet_text_personal')} ${profile.first_name}👋, ${t('greet_text_official')}`}
        </p>
        <p className="text-xl mt-8">{t('para_one')}</p>
        {t('content', { returnObjects: true }).map((textBlock) => {
          if (textBlock.title != 'Power button') {
            return (
              <>
                <p className="text-xl mt-8">{textBlock.title}:</p>
                {textBlock.cards.length > 0 && (
                  <ol className="list-decimal pl-6 text-xl">
                    {textBlock.cards.map((card) => (
                      <li>
                        <div>
                          <p className="text-xl mt-10 mb-6 font-semibold">
                            {card.title}
                          </p>
                          <ul className="list-disc pl-6">
                            <li>
                              <p className="text-xl mt-4">{card.point_one}</p>
                            </li>
                            <li>
                              <p className="text-xl mt-4">{card.point_two}</p>
                            </li>
                            <li>
                              <p className="text-xl mt-4">{card.point_three}</p>
                            </li>
                            {card.point_four && (
                              <li>
                                <p className="text-xl mt-4">
                                  {card.point_four}
                                </p>
                              </li>
                            )}
                          </ul>
                        </div>
                      </li>
                    ))}
                  </ol>
                )}

                {textBlock.points.length > 0 && (
                  <ul className="list-disc pl-6">
                    {textBlock.points.map((point) => (
                      <li>
                        <p className="text-xl mt-4">{point}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            );
          } else {
            return <></>;
          }
        })}

        <p className="text-xl mt-8">{t('para_two')}</p>
        <p className="text-xl mt-8">
          {t('para_three')}{' '}
          <Link to="mailto:contact@nexushorizon.fr" className="font-semibold">
            contact@nexushorizon.fr
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Info;
