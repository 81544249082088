import {
  CopyAll,
  DoneAll,
  Star,
  StarBorderOutlined,
  Subject,
} from '@mui/icons-material';
import React, { memo, useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { generateAlphanumericString } from '../application/protocols/guard/guard';
import { useAppContext } from '../application/stores/AppContext';
import { useChatContext } from '../application/stores/ChatContext';
import CustomSelectedImage from './CustomSelectedImage';
import { useMarketContext } from '../application/stores/MarketContext';

const ChatbotResponse = memo(
  ({
    query,
    response,
    images,
    keyID,
    favourite,
    isFavouritePage,
    chatDate,
    cardName,
    ...props
  }) => {
    const responseRef = useRef(null);
    const {
      setModal,
      modalHeight,
      setIsModalOpen,
      windowWidth,
      windowHeight,
      setModalWidth,
      setModalHeight,
    } = useAppContext();
    const [copyBtnText, setCopyBtnText] = useState('Copy');
    const {
      copiedContent,
      copyToClipboard,
      handleMoreDetails,
      addToFavourite,
      removeFromFavourite,
    } = useChatContext();

    const { bots } = useMarketContext();
    // const [isOnFocus, setIsOnFocus] = useState(false);

    useEffect(() => {
      if (copiedContent.includes(response)) {
        setCopyBtnText('Copied');
      } else {
        setCopyBtnText('Copy');
      }
    }, [copiedContent, response]);

    const handleSelectedImageClick = (e) => {
      setIsModalOpen(true);
      setModal(
        <img
          src={e.target.src}
          style={{ maxHeight: modalHeight - 70 }}
          className="w-full max-w-full h-full max-h-full object-contain flex rounded-b-[26px]"
        />
      );
    };

    useEffect(() => {
      setModalWidth(0.7 * windowWidth);
      setModalHeight(0.8 * windowHeight);
    }, []);

    return (
      <div key={keyID} {...props}>
        {response != 'how can I help you?' && response != '' && (
          <div className="max-md:text-sm w-full flex -flex-row justify-between px-2 -mb-4">
            <div></div>
            <div className="w-fit flex gap-4">
              {!isFavouritePage && (
                <div
                  onClick={() => {
                    handleMoreDetails(query, images);
                  }}
                  className={`px-2 py-0.5 rounded-xl -mt-[6] bg-white text-black text-opacity-50 font-semibold hover:text-white hover:bg-silver border-silver border hover:bg-opacity-90 flex items-center justify-center cursor-pointer`}
                >
                  More Details
                  <Subject size={10} sx={{ marginLeft: 1 }} color="action" />
                  {/* <img src="/more_details.png" width={20} className="ml-3" /> */}
                </div>
              )}

              <div
                onClick={() => {
                  if (copyBtnText == 'Copy') {
                    // const copyText = `* **User:** ${query} \n* **Assistant:** ${response}`;
                    copyToClipboard(response);
                    setCopyBtnText('Copied');
                  } else {
                    copyToClipboard('');
                    setCopyBtnText('Copy');
                  }
                }}
                className={`px-2 py-0.5 rounded-xl -mt-[6] ${
                  copyBtnText == 'Copy'
                    ? 'bg-white text-black text-opacity-50 font-semibold hover:text-white'
                    : 'bg-silver'
                } hover:bg-silver border-silver border hover:bg-opacity-90 flex items-center justify-center cursor-pointer`}
              >
                {copyBtnText}
                {response != 'how can I help you?' && copyBtnText == 'Copy' && (
                  <CopyAll size={10} sx={{ marginLeft: 1 }} color="action" />
                )}
                {response != 'how can I help you?' && copyBtnText != 'Copy' && (
                  <DoneAll size={10} sx={{ marginLeft: 1 }} color="disabled" />
                )}
              </div>
              <div
                onClick={async () => {
                  if (!favourite) {
                    addToFavourite(keyID);
                  } else {
                    removeFromFavourite(keyID);
                  }
                }}
                className={`px-2 py-0.5 rounded-xl -mt-[6] bg-white text-black text-opacity-50 font-semibold hover:text-white hover:bg-silver border-silver border hover:bg-opacity-90 flex items-center justify-center cursor-pointer`}
              >
                {`Favorite`}
                {!favourite && (
                  <StarBorderOutlined
                    size={10}
                    sx={{ marginLeft: 1 }}
                    color="action"
                  />
                )}
                {favourite && (
                  <Star
                    size={10}
                    sx={{ marginLeft: 1, color: '#FFD700' }}
                    color="action"
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {!query && images.length < 1 && (
          <div
            className={`flex flex-col mt-0 justify-start items-start   w-full max-w-full  rounded-lg p-8 border border-silver ${
              copyBtnText != 'Copy' && 'parent-for-copy'
            }`}
          >
            <Markdown remarkPlugins={[remarkGfm]} children={response} />
          </div>
        )}
        {(query != '' || images.length > 0) && (
          <div
            ref={responseRef}
            className={`flex flex-col justify-start items-start   w-full max-w-full  rounded-lg p-4 md:p-8 py-6 border border-silver`}
          >
            <div className="max-md:text-sm flex flex-row w-full justify-between">
              {query != '.' && (
                <div
                  className={`flex flex-col  justify-start items-start  max-w-full  rounded-lg  py-2 px-4 ${
                    copyBtnText != 'Copy' &&
                    // copiedContent.includes(query) &&
                    // 'parent-for-copy'
                    ''
                  }`}
                >
                  {/* {query.split(' * ').map((text) => (
              <Markdown remarkPlugins={[remarkGfm]} children={text} />
            ))} */}
                  {query.split('\n').map((text) => (
                    <Markdown
                      key={generateAlphanumericString(6)}
                      remarkPlugins={[remarkGfm]}
                      children={text}
                    />
                  ))}
                  {/* <Markdown
              children={`* **User:** ${query} \n  * **Assistant:** ${response}`}
            /> */}
                </div>
              )}
              <div className="w-fit flex items-end justify-end flex-wrap  gap-4 max-w-72">
                {images.map((image) => {
                  return (
                    <div
                      className={`flex flex-wrap gap-6  ${
                        images.length < 1 ? 'hidden' : ''
                      }`}
                      style={{
                        paddingBottom:
                          query == '' || query == '.' ? '80px' : '32px',
                        minWidth: 68,
                        maxHeight: 68,
                      }}
                      key={generateAlphanumericString(5)}
                    >
                      <CustomSelectedImage
                        file={image.image}
                        images={images}
                        imageWidth="60px"
                        imageHeight="60px"
                        paddingTop="4px"
                        closeBtnMarginRight="-16px"
                        closeBtnTop="-12px"
                        closeBtnSize={16}
                        closeBtnPadding="4px"
                        borderRadius="10px"
                        marginBottom="-36px"
                        showCloseButton={false}
                        index={generateAlphanumericString(5)}
                        handleSelectedImageClick={handleSelectedImageClick}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {response != 'how can I help you?' && response != '' && (
              <div
                className={`flex flex-col max-md:text-sm mt-4 justify-start items-start chatbot-response  w-full max-w-full  rounded-lg p-4 border border-silver  ${
                  copyBtnText != 'Copy' && 'parent-for-copy'
                } ${cardName ? 'mb-8' : ''}`}
              >
                {/* {String(response).includes('WARNING: contains images\n') &&
                String(response)
                  .split('WARNING: contains images\n')[0]
                  .split('\n')
                  .map((text) => (
                      className={
                        'max-w-full flex flex-wrap overflow-x-auto pb-4'
                      }
                      remarkPlugins={[remarkGfm]}
                      children={text}
                    />
                  ))} */}
                {String(response).includes('WARNING: contains images\n') &&
                  String(response)
                    .split('WARNING: contains images\n')[1]
                    .split('\n\n\n\n\n\n')
                    .map((value, index) => {
                      if (index == 0) {
                        return <></>;
                      } else {
                        let splitted_values = value
                          .split('\n\n')
                          .filter((val) => val != '');
                        return (
                          <div className="max-w-full flex flex-col w-full items-center gap-4 my-12">
                            <p className="max-w-full">
                              <b>Metric {index}</b>: {splitted_values[1]}
                            </p>
                            <img
                              src={splitted_values[0]}
                              className="max-w-full"
                            />
                          </div>
                        );
                      }
                    })}
                {!String(response).includes('WARNING: contains images\n') &&
                  response
                    .split('\n')
                    .map((text) => (
                      <Markdown
                        className={
                          'max-w-full flex flex-wrap overflow-x-auto pb-4 chatbot-response'
                        }
                        remarkPlugins={[remarkGfm]}
                        children={text}
                      />
                    ))}
              </div>
            )}
          </div>
        )}
        {cardName && (
          <div className="-mt-14 mb-4 ml-8 md:text-sm max-md:text-xs">
            {
              bots?.filter((originalBot) => {
                /*eslint-disable*/
                const flag =
                  originalBot.redirect_link
                    .split('/')
                    [originalBot.redirect_link.split('/').length - 1].split('-')
                    .join('_') == cardName;
                /*eslint-enable*/
                return flag;
              })[0]?.display_name
            }
          </div>
        )}
        {chatDate && (
          <div className="-mt-3 mb-6 ml-8 md:text-sm max-md:text-xs">
            {new Date(chatDate).toLocaleDateString()}{' '}
            {new Date(chatDate).toLocaleTimeString()}
          </div>
        )}
      </div>
    );
  }
);

export default ChatbotResponse;
