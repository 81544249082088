import React from 'react';
import { BsSearch } from 'react-icons/bs';

const CustomSearchField = ({
  textarea = false,
  value,
  name,
  type,
  hintText,
  onKeyDown,
  onChange,
  textareaHeight = '40px',
  displaySearchIcon = true,
  className = '',
}) => {
  if (textarea) {
    return (
      <div className={className ? className : 'max-w-full'}>
        <div className="relative w-full">
          {displaySearchIcon && (
            <BsSearch className="w-5 h-5 absolute left-3 top-3 text-gray-400" />
          )}
          <textarea
            onKeyDown={onKeyDown}
            type={type}
            name={name}
            placeholder={hintText}
            value={value}
            onChange={onChange}
            className="p-2 pl-12 border  border-fs rounded-lg hover:border-primary mb-4 w-full leading-5 appearance-none min-h-10 max-h-52"
            style={{ height: textareaHeight }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={className ? className : 'max-w-full'}>
        <div className="relative w-full">
          {displaySearchIcon && (
            <BsSearch className="w-5 h-5 absolute left-3 top-3 text-gray-400" />
          )}
          <input
            type={type}
            name={name}
            placeholder={hintText}
            value={value}
            onChange={onChange}
            className="p-2 pl-12 border border-fs rounded-lg hover:border-primary mb-4 w-full"
          />
        </div>
      </div>
    );
  }
};

export default CustomSearchField;
