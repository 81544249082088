import axios from 'axios';

const fbUrl = 'https://www.facebook.com/profile.php?id=100090224895779';
const inUrl = 'https://www.instagram.com/morwears/';
const twUrl = 'https://twitter.com/MorWears';
const lnUrl = 'https://www.linkedin.com/mwlite/in/mor-wears-15bab826b';

function generateList(n, item) {
  const result = [];
  for (let i = 1; i <= n; i++) {
    result.push(item);
  }
  return result;
}

function getRandomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function formatDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);

  // Format date
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = dateTime.toLocaleDateString(undefined, options);

  // Format day
  const dayOptions = { weekday: 'short' };
  const formattedDay = dateTime.toLocaleDateString(undefined, dayOptions);

  // Format time with AM/PM
  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };
  const formattedTime = dateTime.toLocaleTimeString(undefined, timeOptions);

  return `${formattedDay}, ${formattedDate} at ${formattedTime}`;
}

function convertBackToDate(originalDateStr) {
  try {
    // Create a new Date object from the original date string
    const originalDate = new Date(originalDateStr);

    // Get individual date components
    const formattedYear = originalDate.getFullYear();
    const formattedMonth = String(originalDate.getMonth() + 1).padStart(2, '0');
    const formattedDay = String(originalDate.getDate()).padStart(2, '0');

    // Construct the formatted date string
    const formattedDateStr = `${formattedYear}/${formattedMonth}/${formattedDay}`;

    return formattedDateStr;
  } catch (error) {
    return 'Invalid date format. Please provide a valid date string.';
  }
}

/**
 *
 * @param {*} length length of string to be generated, type: Number
 * @returns completely random string that has number of characters = length
 */
function generateRandomString(length) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters[randomIndex];
  }

  return result;
}

const uploadFile = async (file) => {
  const args = {
    base64str: file.split(',')[1],
    file: 'image_' + generateRandomString(15),

    type: file.split(',')[0].split(';')[0].split(':')[1].split('/')[1],
  };
  const res = await axios.post('https://chat.faxi.ai/upload-file', {
    input: args,
  });
  if (res.status == 200) {
    console.log(res.data.file_path);
    return res.data.file_path;
  } else {
    console.log(res.status);
  }
};

function isBase64(str) {
  str = String(str);
  if (str.split(',').length > 1) {
    if (str.includes('data:') && str.includes(';base64,')) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

const base64toBlob = (data) => {
  const contentType = data.split(':')[1].split(';')[0];
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr(data.split(',')[0].length + 1);

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: contentType });
};

export {
  base64toBlob,
  convertBackToDate,
  fbUrl,
  formatDateTime,
  generateList,
  generateRandomString,
  getRandomInteger,
  inUrl,
  isBase64,
  lnUrl,
  twUrl,
  uploadFile,
};
