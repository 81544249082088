import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosServices } from "../application/protocols/services/api_services";
import CustomButton from "../components/CustomButton";
import CustomTextField from "../components/CustomTextField";
import AuthHeader from "../components/widgets/AuthHeader";

const VerifyOtp = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    otp: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(() => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const res = await AxiosServices.instance.post("/auth/verify-otp/", {
      otp: formData.otp,
    });
    if (res.status) {
      navigate("/login");
    }
  };

  return (
    <div className="bg-background h-screen  font-times text-font ">
      <div className="justify-center items-center bg-white w-2/3 mb-auto h-auto mx-auto rounded-xl">
        <AuthHeader />
        <div className="mx-auto p-4 h-md">
          <h1 className="text-primary text-center"> Verify OTP</h1>
          <CustomTextField
            name={"otp"}
            hintText={"Enter your otp"}
            label={"Enter the OTP"}
            onChange={handleInputChange}
          />
          <CustomButton label="Verify" onClick={handleSubmit} />
        </div>
        <div className="flex py-2 justify-center">
          <p>Forgot Password?</p>
          <a href="/forgot-password" className="px-1 font-inter text-font">
            {" "}
            Reset{" "}
          </a>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
